
module.exports = function replaceSpecialChars(phrase, charReplace = "_") {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
  const p = new RegExp(a.split('').join('|'), 'g');

  return phrase.toString().toLowerCase()
    .replace(/\s+/g, charReplace) // Replace spaces
    .replace(p, c => charReplace) // Replace special characters
    .replace(/&/g, charReplace) // Replace &
    .replace(/[^\w\-]+/g, charReplace) // Replace all non-word characters
    .replace(/\-\-+/g, charReplace) // Replace multiple -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}